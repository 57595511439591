import axios from 'axios';
import store from '@/store';
import { setToken, setEquipmentNo, removeToken, removeEquipmentNo } from '@/utils/cookie';
import Vue from 'vue';
import router from '@/router';
import { Toast } from 'vant';
import { mapActions, mapGetters } from 'vuex';

Vue.use(Toast);

const customAxios = axios.create({
  baseURL: '/api',
  timeout: 600000,
  withCredentials: true,
  store: store
});

// 请求拦截器
customAxios.interceptors.request.use(
  async function (config) {
    const store = config.store;
    if (store) {
      await store.dispatch('fetchFlutterBaseInfo');
      const flutterBaseInfo = store.getters.getFlutterBaseInfo;
      if (flutterBaseInfo) {
        setToken(flutterBaseInfo.token);
        config.data = { ...flutterBaseInfo, ...config.data };
        config.headers['Content-Type'] = 'application/json';
        const token = flutterBaseInfo.token;
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    if ((JSON.parse(localStorage.getItem("BaseInfo"))).token) {
      return config;
    }

    return new Promise((resolve) => {
      const checkToken = setInterval(() => {
        if ((JSON.parse(localStorage.getItem("BaseInfo"))).token) {
          clearInterval(checkToken);
          resolve(config);
        }
      }, 100);
    });

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 响应拦截器
customAxios.interceptors.response.use(
  function (response) {
    if (response.data.code === 1000) {
      Toast(response.data.msg);
      router.push({
        path: "/login",
      });
    }
    if (response.data.code === 9999) {
      Toast(response.data.msg);
    }
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default ({ url, method = 'GET', data = {}, params = {} }) => {
  return customAxios({
    url,
    method,
    data,
    params
  });
};
