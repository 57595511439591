import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss'
import 'amfe-flexible' //
// import { initNativeBridge } from './utils/nativeBridge';
import { Locale } from 'vant';
import filters from './utils/filters'
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);



// import '@vant/touch-emulator';


import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/vant.css'
import touch from 'vue-directive-touch';
Vue.use(touch);

import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
//

//
import { getRandomAlphaNum } from '@/utils/config.js'
Vue.prototype.getRandomAlphaNum = getRandomAlphaNum
import { preventReClick } from '@/utils/directive.js'
Vue.directive('preventReClick', preventReClick)
import { customerActionLog } from './api/utils.js';
Vue.prototype.$customerActionLog = customerActionLog;
// initNativeBridge();



// import Vconsole from 'vconsole';
// let vconsole = new Vconsole;
// vconsole

// Vue.prototype.$api = api
import '@/styles/index.scss'
import '@/icons' // icon
Vue.use(Vant);
Vue.config.productionTip = false
//
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});



// import * as fundebug from "fundebug-javascript";
// import FundebugVue from "fundebug-vue";
// fundebug.apikey = "9e3696edf201948e4206c432f0d999a2a292a6151e027290485a81e05d1b10fd"
// new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x
// console.log(document.documentElement.style.fontSize, '$$$$$$$$$$$$$11111');
// window.addEventListener('resize', setRemUnit);
// window.addEventListener('orientationchange', setRemUnit);

function setRemUnit() {
  console.log(document.documentElement.clientWidth, '$$$$$$$$$$$$$')
  if (document.documentElement.clientWidth <= 680) {
    var remSize = document.documentElement.clientWidth / 10;
    document.documentElement.style.fontSize = remSize + 'px';
  } else {
    document.documentElement.style.fontSize = '48px';
  }
}

setRemUnit();
window.addEventListener('resize', setRemUnit);
// let bsInfo;
document.addEventListener('touchmove', function(event) {
  if (event.scale !== 1) {
    event.preventDefault();
  }
}, { passive: true });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
