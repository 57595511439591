import moment from 'moment';
export default {
  getArea: function (number) {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getDate: function (data) {
    const date = moment(data, 'DD-MM-YYYY');
    if (date.isValid()) {
      const formattedDate = date.format('MMM D, YYYY');
      return formattedDate;
    } else {
      console.error('Invalid date format:', data);
      return null;
    }
  },
  convertAndFormatDate: function (dateString) {
    const date = moment(dateString, 'DD/MM/YYYY');
    console.log(dateString, date, '2222222222222');
    if (date.isValid()) {
      const jsDate = date.toDate();
      const options = { year: "numeric", month: "short", day: "numeric" };
      const currentDate = jsDate.toLocaleDateString("en-US", options);
      return currentDate;
    } else {
      console.error('Invalid date format:', dateString);
      return null;
    }
  },
};
