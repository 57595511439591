import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('@/views/login/index'),

  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/index'),
  },
  {
    path: '/selectId',
    name: 'selectId',
    component: () => import('@/views/idPhoto/selectId'),
  },
  {
    path: '/certificateDemonstration',
    name: 'certificateDemonstration',
    component: () => import('@/views/idPhoto/certificateDemonstration'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/employment',
    name: 'employment',
    component: () => import('@/views/employment/index'),
  },
  {
    path: '/loanHistory',
    name: 'loanHistory',
    component: () => import('@/views/loanHistory/index'),
  },
  {
    path: '/loanDetails',
    name: 'loanDetails',
    component: () => import('@/views/loanDetails/index'),
  },
  {
    path: '/loanAgreement',
    name: 'loanAgreement',
    component: () => import('@/views/loanAgreement/index'),
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('@/views/loan/index'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/guide/index'),
  },
  {
    path: '/code',
    name: 'code',
    component: () => import('@/views/code/index'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms/index'),

  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('@/views/certificate/index'),

  },
  {
    path: '/face',
    name: 'face',
    component: () => import('@/views/face/face'),

  },
  {
    path: '/faceData',
    name: 'faceData',
    component: () => import('@/views/face/faceData'),

  },
  {
    path: '/preRiskControl',
    name: 'preRiskControl',
    component: () => import('@/views/preRiskControl/index'),

  },
  {
    path: '/extend',
    name: 'extend',
    component: () => import('@/views/extend/index'),

  },
  {
    path: '/repayment',
    name: 'repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/me',
    name: 'me',
    component: () => import('@/views/me/index'),

  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),

  },
  {
    path: '/repaymentWay',
    name: 'repaymentWay',
    component: () => import('@/views/repaymentWay/index'),
  },
  {
    path: '/repaymentDetails',
    name: 'repaymentDetails',
    component: () => import('@/views/repaymentDetails/index'),
  },
  {
    path: '/yourself',
    name: 'yourself',
    component: () => import('@/views/yourself/index'),
  },
  {
    path: '/Receiving',
    name: 'Receiving',
    component: () => import('@/views/Receiving/index'),
  },
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   let appId = localStorage.getItem("appCode")
//   document.title = `${appId}-${to.name}`
//   const pageName = to.name;
//   window.gtag("event", "page_view", {
//     page_title: pageName,
//   });
//   const enterTime = Date.now();
//   to.meta.leaveGuard = router.afterEach((to, from) => {
//     const stayTime = Date.now() - enterTime;
//     window.gtag("event", "stay_time", {
//       value: stayTime,
//       event_category: "timing",
//       event_label: pageName,
//     });
//     to.meta.leaveGuard();
//   });
//   next()

// })
export default router
