import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import router from '@/router';
Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  state: {
    flutter_baseInfo: {},
    flutter_cId: '',
    ifscCode: '',
    results: {
      appCode: '1000',
      version: '1',
      userCode: localStorage.getItem('userCode') || null,
      imei: '',
      idfv: '',
      idfa: '',
      ip: '',
      longitude: '',
      latitude: '',
      token: localStorage.getItem('token') || null,
      smsToken: '',
      gotoLoan:''
    }
  },
  getters: {
    ...getters,
    getFlutterBaseInfo: state => state.flutter_baseInfo
  },
  mutations: {
    getFromApp(state, n) {
      state.flutter_baseInfo = n;
    },
    getCustomerId(state, n) {
      state.flutter_cId = n;
    },
    getIfscCode(state, n) {
      state.ifscCode = n;
    },
    SET_FLUTTER_BASE_INFO(state, info) {
      state.flutter_baseInfo = info;
    },
    updateToken(state, newToken) {
      localStorage.setItem('token', newToken);
      state.results.token = newToken;
    },
    updateUserCode(state, newCode) {
      localStorage.setItem('userCode', newCode);
      state.results.userCode = newCode;
    },
  },
  actions: {
    async fetchFlutterBaseInfo({ state, commit }) {
      const result = JSON.stringify(state.results);
      console.log(result, '))))))))))');
      if (result) {
        localStorage.setItem("BaseInfo", result);
        localStorage.setItem("appCode", JSON.parse(result).appCode);
        commit('SET_FLUTTER_BASE_INFO', JSON.parse(result));
        if(!JSON.parse(result).token){
          router.push({
            path: "/login",
          });
        }
      }
    },
  },
  modules,
});

export default store;
